import { Injectable, Provider } from '@angular/core';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import {
  cloudRoleTelemetryInitializer,
  isDependencyTelemetryItem,
  TELEMETRY_INITIALIZER,
  TelemetryInitializer
} from '@mri-platform/angular-app-insights';
import { AgWidgetConfig, ApiConfig } from '@mri-platform/shared/core';

const addAgBarCloudRoleTo = cloudRoleTelemetryInitializer('AG Bar');

/**
 * Add AG bar as the cloud role name to fetch calls that AG bar is making to it's backend
 */
@Injectable()
export class AgBarCloudRoleTelemetryInitializer implements TelemetryInitializer {
  constructor(
    private apiConfig: ApiConfig,
    private agWidget: AgWidgetConfig
  ) {}

  enrichOrFilter(item: ITelemetryItem): boolean | void {
    if (!this.agWidget.enableAppInsights || !this.isAgBarApiRequest(item)) return;

    addAgBarCloudRoleTo(item);
  }

  isAgBarApiRequest(item: ITelemetryItem) {
    if (!isDependencyTelemetryItem(item)) return false;

    const targetUrl: string = item.baseData?.target ?? '';
    // currently AG Admin API is a path of the AG API
    return targetUrl.startsWith(this.agWidget.baseApiUrl) && !targetUrl.includes(this.apiConfig.path);
  }
}

export const AgBarCloudRoleTelemetryInitializerProvider: Provider = {
  provide: TELEMETRY_INITIALIZER,
  useClass: AgBarCloudRoleTelemetryInitializer,
  multi: true
};
