import { Component, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@mri-platform/shared/core';
import { HeaderLogo } from '@mri-platform/shared/ui-main-nav-bar';
import { MenuService } from '../../ui-services';

@Component({
  selector: 'mri-shared-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {
  renderAgWidget = !environment.integrationTest;
  @Input() headerLogo: HeaderLogo | null = null;
  constructor(
    public menu: MenuService,
    titleService: Title
  ) {
    this.headerLogo = this.headerLogo ?? { text: titleService.getTitle(), url: '' };
  }
}
