import { NgModule } from '@angular/core';
import { DirtyStateService } from '@mri-platform/shared/core';
import { StoreModule } from '@ngrx/store';
import * as fromNavigation from './reducers';
import { DefaultDirtyStateService } from './services/default-dirty-state.service';

@NgModule({
  imports: [StoreModule.forFeature(fromNavigation.navigationFeatureKey, fromNavigation.reducer)],
  exports: [],
  providers: [{ provide: DirtyStateService, useClass: DefaultDirtyStateService }]
})
export class NavigationModule {}
