import { FactoryProvider } from '@angular/core';
import { AppInsightsConfig } from '@mri-platform/angular-app-insights';
import { SanitizedError } from '@mri-platform/angular-error-handling';
import { AgWidgetConfig, ApiConfig, Settings } from '@mri-platform/shared/core';
import { AuthService, LoadedUser } from '@mri/angular-wfe-proxy-oidc';
import { getAppInsightsUserInfo } from './get-app-insights-user-info';

const AppInsightsConfigFactory = (
  settings: Settings,
  apiConfig: ApiConfig,
  agWidget: AgWidgetConfig,
  authService: AuthService
): AppInsightsConfig<LoadedUser> => {
  const aiSettings = (settings.appInsights as AppInsightsConfig) ?? {};

  const correlationHeaderDomains = [apiConfig.domain].concat(agWidget.enableAppInsights ? [agWidget.apiDomain] : []);

  return AppInsightsConfig.from({
    autoTrackPageVisitTime: true,
    // todo: enable to track AG bar api calls once AgBarCloudRoleTelemetryInitializer proves successful
    disableFetchTracking: agWidget.enableAppInsights ? false : true,
    enableAjaxResponseLengthTracking: true,
    enableCorsCorrelation: true,
    enableRouterChangeTracking: true,
    correlationHeaderDomains,
    ...aiSettings,
    errorSelector: SanitizedError.getErrorLogEntry,
    loginEvents: authService.userLoaded$,
    userSelector: getAppInsightsUserInfo
  });
};

export const AppInsightsConfigProvider: FactoryProvider = {
  provide: AppInsightsConfig,
  useFactory: AppInsightsConfigFactory,
  deps: [Settings, ApiConfig, AgWidgetConfig, AuthService]
};
